<template>
	<div id="content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="box">
						<b-alert show class="mb-4" v-html="$t('localisation.message')">
						</b-alert>
						<CustomTable
							ref="table"
							id_table="localisation"
							:items="localisations"
							:busy="table_busy"
							primaryKey="localisation_id"
						/>
					</div>
				</div>
			</div>
		</div>
		<ModalAddLocalisation 
			ref="modalAddLocalisation"
			:refresh_table.sync="refresh_table"
			:localisation="selected"
		/>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
    import Localisation from "@/mixins/Localisation.js"
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'Localisation',
		mixins: [Localisation, TableAction, Navigation],
		data () {
			return {
				table_busy: false,
				localisations: [],
				events_tab: {
                    'TableAction::goToAddLocalisation': () => {
                    	this.selected.id = null
                    	this.selected.libelle = ""
                    	this.selected.nb_horse = ""
                    	this.selected.lieu = null
                    	this.selected.lat = null
                    	this.selected.lng = null

                    	this.openModalLocalisation()
                    },
                    'TableAction::goToEditLocalisation': (localisation) => {
                    	this.selected.id = localisation[0].localisation_id
                    	this.selected.libelle = localisation[0].localisation_libelle
                    	this.selected.nb_horse = localisation[0].localisation_nb_horse
                    	this.selected.lieu = localisation[0].localisation_lieu
                    	this.selected.lat = localisation[0].localisation_lat
                    	this.selected.lng = localisation[0].localisation_lng
                        this.openModalLocalisation()
                    },
                    'TableAction::goToDeleteLocalisation': this.deleteLocalisation
                },
                refresh_table: false,
                selected: {
                	lieu: null,
                	id: null,
                	libelle: "",
                	nb_horse: "",
                	lat: null,
                	lng: null
                }
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadLocalisation()
			},

			async loadLocalisation() {
				this.table_busy = true
				this.localisations = await this.getLocalisation()
				this.table_busy = false
			},

			openModalLocalisation() {
				this.$refs.modalAddLocalisation.openModal()

			},

			deleteLocalisation(loc) {
				let tab_promises = []
				for(let i in loc) {
					tab_promises.push(this.devalideLocalisation(loc[i].localisation_id))
				}

				Promise.all(tab_promises)
				.then(() => {
					this.$refs.table.resetCachedItems()
					this.init_component()
					this.successToast("toast.info_save_succes")
				})
				.catch(() => {
					this.failureToast()
				})
			}
		},
		watch: {
			refresh_table() {
				this.loadLocalisation()
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			ModalAddLocalisation: () => import('@/components/Localisation/ModalLocalisation'),
		}
	}
</script>